import Vue from "vue";
import Router from "vue-router";

  Vue.use(Router);
  
  export default new Router({
	mode: "history",
	routes: [
    {
		path: "/",
		alias: "/",
		name: "exercises",
		component: () => import("./components/Landing")
    },
    {
		path: "/practice/:topic/:id",
		alias: "/practice/topic",
		name: "practice",
		component: () => import("./components/Practice")
    },
    {
		path: "/test/:topic/:id",
		alias: "/test",
		name: "test",
		component: () => import("./components/Test")
    },
    {
		path: "/overview/:topic",
		alias: "/overview",
		name: "overview",
		component: () => import("./components/OverView")
    },
	{
		path: "/home",
		alias: "/home",
		name: "home",
		component: () => import("./components/Home")
    },
	{
		path: "/login",
		alias: "/login",
		name: "login",
		component: () => import("./components/Login")
	},
	]
  });
  