<template>
<div id="app">

    <!-- Header start -->
    <header class="header">
        <div class="header__container flex-space">
            <a href="/home">
                <img src="https://practicelinux.com/logo.png" alt="practice linux">
            </a>
            <nav class="nav">
                <ul class="nav__menu" v-if="condition">
                    <li>
                        <a class="nav__menu--item btn btn__default" href="/login" v-if="key === null">Log in</a>&nbsp;
                    </li>
                </ul>
            </nav>
            <nav class="mobile-nav">
                <ul class="nav__menu">
                    <li>
                        <a class="nav__menu--item login" href="/login" v-if="key === null">Log in</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- Header end -->

    <router-view></router-view>

</div>
</template>

<script>

export default {
    name: 'app',
    created: function() {
        this.key = this.$cookie.get('key');
    },
    computed: {
    condition() {
      console.log(this.$route.name);
      console.log('condition');
      return this.$route.name === 'home' || this.$route.name === "exercises" || this.$route.name  === 'overview' || this.$route.name === 'practice' || this.$route.name === 'test';
    }    
    },
    data: function () {
      return {
          key: ''
      }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000;
    height: 100%; 
}

body {

}

.header h1 {
    color: #2c3e50;
}


#text {
    color: #2c3e50;
}

.hello {
    width: 100%;
}

.nav__menu--item.btn.btn__default.login {
    background: rgb(44, 151, 75);
    color: white;
    border-color: rgba(27, 31, 35, 0.15);
}

@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap&subset=latin-ext";
@import "./assets/styles/css.css";
@import './assets/styles/bootstrap.css';
@import './assets/styles/swiper.css';
@import './assets/styles/keyboard.css';
@import './assets/styles/styles.css';
@import './assets/styles/magnific-popup.css';
@import './assets/styles/swiper.css';
@import './assets/font/fontawesome-all.css';

@import './assets/hero/css2.css';
@import './assets/hero/normalize.css';
@import './assets/hero/style.css';
@import './assets/hero/responsive.css';

</style>
